import {Observable, of, switchMap, timer} from 'rxjs';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import {catchError, map} from 'rxjs/operators';

export const urlValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  let isValid = true;
  try {
    if (control.value) {
      const url = new URL(control.value);
      if (!url.host || !url.origin || !url.protocol) {
        isValid = false;
      }
    }
  } catch {
    isValid = false;
  }
  return isValid ? null : {invalidUrl: true};
};

export function existsAsyncValidator(checkFn: (value: any) => Observable<boolean>, delay = 300): AsyncValidatorFn {
  return (control: AbstractControl) => {
    return timer(delay).pipe(
      switchMap(() => {
        return checkFn(control.value)
          .pipe(
            map((response) => {
              return response ? {exists: true} : null;
            }),
            catchError(() => of(null)) // TODO: error?
          );
      })
    )
  }
}


export function patternValidator(pattern: string | RegExp, errorMessage: string): ValidatorFn {
  const patternValidator = Validators.pattern(pattern);

  return (control: AbstractControl): ValidationErrors | null => {
    const validationResult = patternValidator(control);

    if (validationResult) {
      return { pattern: errorMessage };
    }
    return null;
  };
}

export function emailValidator(): ValidatorFn {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }
    const valid = emailRegex.test(value);
    return valid ? null : { email: true };
  };
}
