import {Injectable} from '@angular/core';
import {CONTRACT_TYPES, TContractType} from '../../api/shared/common';
import {find, memoize} from 'lodash';
import {Info} from 'luxon';
import {TranslateService} from '@ngx-translate/core';


@Injectable({
  providedIn: 'root'
})
export class ConstantsProviderService {
  archivableStatus;

  constructor(translate: TranslateService) {
    this.archivableStatus = [
      {label: translate.instant('shared.archiveStatus.active'), value: false},
      {label: translate.instant('shared.archiveStatus.archived'), value: true}
    ];
  }

  getArchivableStatusAsString(status: boolean): string {
    return find(this.archivableStatus, { value: status})!.label;
  }

  contractTypeIcon: (type: TContractType) => string | null = memoize((type) => {
    const t = CONTRACT_TYPES.find((t) => t === type);
    return !!t ? `assets/images/team/${t}.svg` : null;
  });

  weekDays(unit: 'short' | 'long' = 'short'): Array<string> {
    return Info.weekdays(unit);
  }
}

