import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-spinnerizer',
  template: `
    <div #container
         class="spinnerizer-container"
         [class.spinnerizer-overlay]="!!target"
         [class.spinnerizer-separate]="!target"
         [class.spinnerizer-pos-top]="spinnerPosition === 'top'"
         [style.display]="!active ? 'none': 'flex'">
      <div class="spinnerizer-spinner">
        <div *ngIf="text" class="spinnerizer-text">{{ text }}</div>
        <div class="spinner-container">
          <div class="spinner-image"></div>
          <img [src]="invertSpinner ? 'assets/images/loading-light.svg' : 'assets/images/loading-dark.svg'" width="30">
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .spinnerizer-container {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: progress;
        visibility: visible;
      }

      .spinnerizer-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // backdrop-filter: grayscale(.8) contrast(0.9);
        z-index: 100;
      }

      .spinnerizer-pos-top {
        justify-content: flex-start;
      }

      .spinnerizer-separate {
        padding: 2em;
      }

      .spinnerizer-spinner .spinnerizer-text {
        padding: 2px 14px;
        line-height: 1;
        border-radius: 14px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
      }

      .spinnerizer-invert-text .spinnerizer-text {
        background-color: rgba(0, 0, 0, 0.6);
        color: rgba(255, 255, 255, 0.8);
      }

      .spinnerizer-spinner .spinnerizer-text ~ .spinner-container {
        margin-top: 6px;
      }

      .spinner-container {
        display: inline-block;
        padding: 2px;
      }

      .spinnerizer-invert-spinner .spinner-container {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 6px;
      }

    `
  ],
  standalone: true,
  imports: [NgIf]
})
export class SpinnerizerComponent implements OnDestroy, AfterViewInit {
  @Input() target: any;
  @Input() text?: string;
  @Input() invertText = true;
  @Input() invertSpinner = false;
  @Input() spinnerPosition: 'center' | 'top' = 'center';
  @ViewChild('container', {static: true}) containerRef!: ElementRef;
  private theActive!: boolean;

  @Input() get active(): boolean {
    return this.theActive;
  }

  set active(val: boolean) {
    this.theActive = val;
    if (this.containerRef.nativeElement) {
      if (this.theActive) {
        this.activate();
      } else {
        this.deactivate();
      }
    }
  }

  constructor(public el: ElementRef) {
  }

  ngAfterViewInit(): void {
    if (this.invertText) {
      this.containerRef.nativeElement.classList.add('spinnerizer-invert-text');
    }
    if (this.invertSpinner) {
      this.containerRef.nativeElement.classList.add('spinnerizer-invert-spinner');
    }
  }

  ngOnDestroy(): void {
    this.deactivate();
  }

  private activate(): void {
    if (this.target) {
      if (this.target instanceof HTMLElement) {
        const div: HTMLElement = this.target as HTMLElement;
        div.appendChild(this.containerRef.nativeElement);
        const style = div.style;
        style.position = 'relative';
      }
    }
  }

  private deactivate(): void {
    if (this.target) {
      this.el.nativeElement.appendChild(this.containerRef.nativeElement);
    }
  }
}


@Directive({
  selector: '[spinnerizer]',
  standalone: true,
})
export class SpinnerizerDirective implements AfterViewInit, OnChanges {
  @Input('spinnerizer') active = false;
  @Input() text?: string;
  @Input() invertText = true;
  @Input() invertSpinner = false;

  private spinnerizerRef!: ComponentRef<SpinnerizerComponent>;

  constructor(private el: ElementRef,
              private viewContainerRef: ViewContainerRef,
              private cdr: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reflectChanges();
  }

  reflectChanges(): void {
    if (this.spinnerizerRef) {
      this.spinnerizerRef.setInput('active', this.active);
      this.spinnerizerRef.setInput('target', this.el.nativeElement);
      this.spinnerizerRef.setInput('text', this.text);
      this.spinnerizerRef.setInput('invertText', this.invertText);
      this.spinnerizerRef.setInput('invertSpinner', this.invertSpinner);
      this.cdr.detectChanges();
    }

  }

  ngAfterViewInit(): void {
    this.spinnerizerRef = this.viewContainerRef.createComponent(SpinnerizerComponent);
    const spinnerizerEl = this.spinnerizerRef.location.nativeElement;
    this.el.nativeElement.parentElement.insertBefore(spinnerizerEl, this.el.nativeElement.previousSibling);
    this.reflectChanges();
  }
}
