import {Component, inject, Injectable, Input, OnChanges, Optional, SimpleChanges} from '@angular/core';
import {startCase} from 'lodash';
import {plural} from '../../util/util';

import {AbstractEmbeddableComponent} from '../abstract-embeddable-component';
import {TreeSelectComponent} from '../tree-select.component';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule} from '@angular/forms';
import {MultiSelectModule} from 'primeng/multiselect';
import {SharedModule} from 'primeng/api';
import {TooltipOnOverflowDirective} from '../tooltip-on-overflow.directive';
import {AsyncPipe, NgClass} from '@angular/common';
import {TableModule} from 'primeng/table';
import {OnDemandResourceLoaderService} from '../../services/resources/on-demand-resource-loader.service';
import {ChangesDebounceDirective} from '../changes-debounce.directive';
import {InputTextModule} from 'primeng/inputtext';
import {NullableDirective} from '../nullable.directive';
import {getMatchModeOptions} from './table-query';
import {TranslateModule} from '@ngx-translate/core';

export type TColumnFilter =
  'text' | 'numeric' | 'date' | 'boolean' |
  'optionsIn' | 'optionsContains' | 'optionsEquals' | 'optionsArray';

export type TFrozenColumn = 'lastLeft' | 'firstRight' | 'left' | 'right';

export interface IColumnInfo {
  fixedWidth?: number;
  frozen?: TFrozenColumn;
}

@Injectable()
export class ColumnsInfoService {
  readonly columnInfos: { [name: string]: IColumnInfo } = {};
  readonly columns: { [field: string]: string; } = {};

  registerColumn(th: ColumnHeaderComponent): void {
    this.columns[th.field] = th.label;
  }

  putFixedWidth(name: string, fixedWidth: number): void {
    this.getColumn(name).fixedWidth = fixedWidth;
  }

  putFrozen(name: string, frozen: TFrozenColumn): void {
    this.getColumn(name).frozen = frozen;
  }

  private getColumn(name: string): IColumnInfo {
    if (this.columnInfos[name] == null) {
      this.columnInfos[name] = {};
    }
    return this.columnInfos[name];
  }

  getFrozen(name: string): TFrozenColumn | undefined {
    return this.columnInfos[name]?.frozen;
  }

  getWidthStyle(name: string): { [prop: string]: any } {
    const col = name === '$selector' ? {fixedWidth: 45} : this.columnInfos[name];
    return !!col && col.fixedWidth != null ? {
      width: col.fixedWidth + 'px',
      maxWidth: col.fixedWidth + 'px',
      minWidth: col.fixedWidth + 'px'
    } : {};
  }
}


@Component({
  selector: 'app-th',
  template: `
    <ng-template #template>

      <th [style]="_thStyle"
          pFrozenColumn [frozen]="!!frozen"
          [pSortableColumn]="sortField ?? field" [pSortableColumnDisabled]="!sortable"
          [class.mt-last-left-frozen-column]="frozen === 'lastLeft'"
          [class.mt-first-right-frozen-column]="frozen === 'firstRight'"
          [alignFrozen]="frozen === 'firstRight' || frozen === 'right' ? 'right' : undefined!"
          [ngClass]="thStyleClass">
        <div class="flex justify-content-between align-items-center w-full">
          <div class="mt-overflow-ellipsis" appTooltipOnOverflow>{{ label }}</div>
          @if (sortable) {
            <p-sortIcon class="align-self-center" [field]="sortField ?? field"></p-sortIcon>
          }
          @if (['optionsIn', 'optionsContains', 'optionsArray'].includes(filterType ?? '')) {
            <p-columnFilter [field]="filterField ?? field"
                            [matchMode]="filterType === 'optionsIn' ? 'in' : 'contains'"
                            display="menu" class="ml-auto"
                            [showMatchModes]="filterType === 'optionsArray'" [showOperator]="false"
                            [showAddButton]="false"
                            [matchModeOptions]="getMatchModeOptions('array')"
                            [showApplyButton]="false" [hideOnClear]="true">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect [ngModel]="value"
                               [options]="onDemandOptions ? $any(onDemandLoader.observe(onDemandOptions) | async) : options"
                               [optionLabel]="optionLabel!"
                               [optionValue]="optionValue!"
                               [virtualScroll]="optionsVirtualScroll"
                               [showToggleAll]="!optionsVirtualScroll"
                               [virtualScrollItemSize]="39"
                               [placeholder]="filterType === 'optionsArray' ? ''
                                  : (filterType === 'optionsIn' ? 'shared.table.optionsFilter.any' : 'shared.table.optionsFilter.contains') | translate"
                               [selectedItemsLabel]="optionsSelectedLabel!"
                               (onChange)="filter($event.value)">
                  @if (!!optionIcon) {
                    <ng-template let-item pTemplate="item">
                      <div class="flex align-items-center">
                        <img class="mr-2" [src]="item[optionIcon!]" width="24"/>
                        <div>{{ optionLabel ? item[optionLabel!] : item }}</div>
                      </div>
                    </ng-template>
                  }
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          } @else {
            @switch (filterType) {
              @case ('optionsEquals') {
                <p-columnFilter [field]="filterField ?? field"
                                matchMode="equals" display="menu" class="ml-auto"
                                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
                                [showApplyButton]="false" [hideOnClear]="true">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    @if (optionsType === 'array') {
                      <p-dropdown [ngModel]="value"
                                  [options]="onDemandOptions ? $any(onDemandLoader.observe(onDemandOptions) | async) : options"
                                  [optionLabel]="optionLabel!"
                                  [optionValue]="optionValue!"
                                  [placeholder]="'shared.search' | translate"
                                  (onChange)="filter($event.value)">
                      </p-dropdown>
                    } @else if (optionsType === 'tree') {
                      <app-tree-select [filter]="true"
                                       [ngModel]="value"
                                       [options]="options!"
                                       [placeholder]="'shared.search' | translate"
                                       (ngModelChange)="filter($event)">
                      </app-tree-select>
                    }
                  </ng-template>
                </p-columnFilter>
              }
              @case ('text') {
                <p-columnFilter #columnFilter [field]="filterField ?? field"
                                type="text"
                                matchMode="contains" display="menu" class="ml-auto"
                                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
                                [showApplyButton]="false" [showClearButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <span class="p-input-icon-left p-input-icon-right">
                      <i class="pi pi-search"></i>
                      <i class="pi pi-times cursor-pointer" (click)="filter(null); columnFilter.hide()"></i>
                      <input #searchModel="ngModel" [ngModel]="value"
                             pInputText type="text" [style.width]="'200px'"
                             [placeholder]="'shared.search' | translate"
                             nullable
                             appChangesDebounce (debounced)="filter($event)"/>
                    </span>
                  </ng-template>
                </p-columnFilter>
              }
              @case ('numeric') {
                <p-columnFilter [field]="filterField ?? field"
                                type="numeric"
                                display="menu" class="ml-auto" [hideOnClear]="true">
                </p-columnFilter>
              }
              @case ('date') {
                <p-columnFilter [field]="filterField ?? field"
                                type="date"
                                display="menu" class="ml-auto"
                                matchMode="dateBefore" [hideOnClear]="true"
                                [matchModeOptions]="getMatchModeOptions('timestamp')">
                </p-columnFilter>
              }
              @case ('boolean') {
                <p-columnFilter [field]="filterField ?? field"
                                type="boolean"
                                display="menu" class="ml-auto"
                                matchMode="is" [showApplyButton]="false" [hideOnClear]="true">
                </p-columnFilter>
              }
            }
          }
        </div>
      </th>

    </ng-template>
  `,
  styles: [`
    ::ng-deep .p-column-filter-buttonbar:not(:has(button)) {
      display: none;
    }
  `],
  standalone: true,
  imports: [
    TableModule, NgClass, TooltipOnOverflowDirective, SharedModule, MultiSelectModule, FormsModule, DropdownModule,
    TreeSelectComponent, AsyncPipe, ChangesDebounceDirective, InputTextModule, NullableDirective, TranslateModule
  ]
})
export class ColumnHeaderComponent extends AbstractEmbeddableComponent implements OnChanges {
  @Input({required: true}) field!: string;
  @Input() label!: string;
  @Input() frozen?: TFrozenColumn;
  @Input() fixedWidth?: number;
  @Input() thStyle: { [prop: string]: any } = {};
  @Input() thStyleClass?: string;
  @Input() sortable = true;
  @Input() sortField?: string;
  @Input() filterType?: TColumnFilter;
  @Input() filterField?: string;
  @Input() optionsVirtualScroll = false;
  @Input() options?: any[];
  @Input() onDemandOptions?: string;
  @Input() optionLabel?: string;
  @Input() optionValue?: string;
  @Input() optionsSelectedLabel?: string;
  @Input() optionsType: 'array' | 'tree' = 'array';
  @Input() optionIcon?: string;
  padding = 18;
  protected _thStyle: { [prop: string]: any } = {};
  private columnsInfo = inject(ColumnsInfoService, {optional: true});
  protected onDemandLoader = inject(OnDemandResourceLoaderService);

  ngOnChanges(changes: SimpleChanges): void {
    const widthStyle: any = {};
    if (this.fixedWidth != null) {
      /* widthStyle.maxWidth = widthStyle.width = */
      widthStyle.minWidth = widthStyle.width = this.fixedWidth - this.padding + 'px';
    }
    this._thStyle = {
      ...widthStyle,
      ...this.thStyle
    };

    if (!this.label) {
      this.label = startCase(this.field);
    }
    if (!!this.optionValue && !this.optionLabel) {
      this.optionLabel = this.optionValue;
    }
    if (!this.optionsSelectedLabel) {
      this.optionsSelectedLabel = `{0} ${plural(this.label)} selected`;
    }
    if (this.columnsInfo) {
      if (this.fixedWidth != null) {
        this.columnsInfo.putFixedWidth(this.field, this.fixedWidth - this.padding);
      }
      if (this.frozen != null) {
        this.columnsInfo.putFrozen(this.field, this.frozen);
      }
    }
  }

  override ngOnInit() {
    super.ngOnInit();
    if (this.columnsInfo) {
      this.columnsInfo.registerColumn(this);
    }
  }

  protected readonly getMatchModeOptions = getMatchModeOptions;
}

@Component({
  selector: 'app-th-selector',
  template: `
    <ng-template #template>
      <th style="width: 45px; max-width: 45px" pFrozenColumn>
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
    </ng-template>
  `,
  standalone: true,
  imports: [TableModule]
})
export class ColumnHeaderSelectorComponent extends AbstractEmbeddableComponent {
}
