import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  QueryList,
  Renderer2,
  TemplateRef
} from '@angular/core';
import {PrimeTemplate, SharedModule} from 'primeng/api';
import {copyToClipboard} from '../../util/util';
import {Router} from '@angular/router';
import {AbstractEntityEditorBase} from './abstract-entity-editor-base';
import {TooltipModule} from 'primeng/tooltip';
import {RippleModule} from 'primeng/ripple';
import {ButtonModule} from 'primeng/button';
import {NgIf, NgTemplateOutlet} from '@angular/common';
import {DialogModule} from 'primeng/dialog';
import {MessageModule} from 'primeng/message';

import {confirmExitUnsaved} from '../confirm';
import {TranslateModule} from '@ngx-translate/core';

export interface IEntityEditDialogShowOptions {
  link?: string;
  onApply?: (data: any) => void;
}

@Component({
  selector: 'app-entity-edit-dialog',
  template: `
    <p-dialog styleClass="mt-edit-dialog"
              [(visible)]="visible"
              [position]="$any(position)"
              [maximizable]="true"
              [closable]="false"
              [closeOnEscape]="false"
              (onHide)="onHide()"
              [modal]="true" [style]="{width, minWidth}" appendTo="body">
      <ng-template pTemplate="header">
        <div class="flex align-items-center">
          <div class="text-2xl">{{ title ?? editor?.title }}</div>
          <button *ngIf="link" pButton pRipple icon="pi pi-link"
                  class="ml-2 p-button-rounded p-button-text" (click)="copyLink($event)"
                  [pTooltip]="'shared.entityEditor.copyLinkToClipboard' | translate" tooltipPosition="bottom"></button>
          <button *ngIf="link" pButton pRipple icon="pi pi-external-link"
                  class="ml-1 p-button-rounded p-button-text" (click)="navigateToLink()"
                  [pTooltip]="'shared.entityEditor.goToEditPage' | translate" tooltipPosition="bottom"></button>
        </div>
      </ng-template>
      <ng-template pTemplate="footer">
        <div class="flex align-items-center justify-content-between">
          <div>
            @if (!editor?.canSubmit() && editor?.applyAttempted && !editor?.isValid()) {
              <p-message severity="warning" [text]="'shared.entityEditor.invalidDataInForm' | translate" class="mr-3"></p-message>
            }
          </div>
          <div>
            <p-button icon="pi pi-check"
                      [disabled]="!editor?.canSubmit()"
                      (onClick)="performApply()"
                      [label]="'shared.actions.apply' | translate"
                      styleClass="p-button-text"></p-button>
            <p-button icon="pi pi-times" (onClick)="close()"
                      [label]="'shared.actions.cancel' | translate"
                      styleClass="p-button-text"></p-button>
          </div>
        </div>
      </ng-template>
      @if (visible) {
        <ng-container *ngTemplateOutlet="tplEditor; context:{$implicit: param}"></ng-container>
      }
    </p-dialog>  `,
  standalone: true,
  imports: [
    DialogModule, SharedModule, NgIf, ButtonModule, RippleModule, TooltipModule, NgTemplateOutlet,
    MessageModule, TranslateModule
  ]
})
export class EntityEditDialogComponent implements AfterContentInit, OnDestroy {
  @Input() title?: string;
  @Input() position = 'center';
  @Input() width = 'auto';
  @Input() minWidth = 'auto';
  @Input() link?: string;
  @Output() protected apply = new EventEmitter<{ param: any; data: any }>();
  protected param: any;
  protected visible = false;
  protected onApply?: (data: any) => void;
  protected tplEditor!: TemplateRef<any>;
  @ContentChildren(PrimeTemplate) protected templates!: QueryList<PrimeTemplate>;
  @ContentChild(AbstractEntityEditorBase) protected editor?: AbstractEntityEditorBase<any>;
  private documentKeydownListener: VoidFunction | null = null;
  private closing = false;
  private cdr = inject(ChangeDetectorRef);
  private router = inject(Router);
  private elRef = inject(ElementRef);
  private renderer = inject(Renderer2);

  ngAfterContentInit(): void {
    this.templates.forEach((tpl) => {
      if (!tpl.getType() || tpl.getType() === 'editor') {
        this.tplEditor = tpl.template;
      }
    });
  }

  show(param: any, options?: IEntityEditDialogShowOptions): void {
    this.onApply = options?.onApply;
    this.link = options?.link;
    this.param = param;
    this.visible = true;
    this.cdr.detectChanges();
    this.bindEscapeListener();
  }

  protected performApply(): void {
    if (this.editor?.canPerformApply()) {
      this.editor.apply((result: any) => {
        this.visible = false;
        this.apply.emit({param: this.param, data: result});
        if (this.onApply) {
          this.onApply(result);
        }
      });
    }
  }

  async close() {
    this.closing = true;
    if (!!this.editor?.isDataChanged()) {
      this.visible = !await confirmExitUnsaved();
    } else {
      this.visible = false;
    }
    this.closing = false;
  }


  bindEscapeListener(): void {
    this.documentKeydownListener = this.renderer.listen(this.elRef.nativeElement.ownerDocument, 'keydown', (event) => {
      if (event.which === 27) {
        if (!this.closing) {
          this.close();
        }
      }
    });
  }

  onHide() {
    this.unbindEscapeListener();
  }

  copyLink(event: MouseEvent): void {
    if (this.link) {
      copyToClipboard(location.origin + this.link);
    }
  }

  navigateToLink(): void {
    if (this.link) {
      this.router.navigateByUrl(this.link);
    }
  }

  private unbindEscapeListener() {
    if (this.documentKeydownListener) {
      this.documentKeydownListener();
      this.documentKeydownListener = null;
    }
  }

  ngOnDestroy(): void {
    this.unbindEscapeListener();
  }

}
