import {
  AfterContentInit,
  Component,
  ContentChildren,
  Injector,
  Input,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {AbstractEntityTable} from './abstract-entity-table';
import {PrimeTemplate, SharedModule} from 'primeng/api';
import {Table, TableModule} from 'primeng/table';
import {ITableMenuItem, TableToolbarComponent} from './table-menus';
import {ColumnHeaderSelectorComponent, ColumnsInfoService} from './column-header.component';
import {inheritanceProvider} from '../../util/util';
import {TableCellSelectorComponent} from './table-cell.component';
import {NgTemplateOutlet} from '@angular/common';
import {SpinnerizerDirective} from '../spinnerizer.component';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-entity-table',
  template: `
    <div class="mt-table-container">
      <app-table-toolbar [omitMenuItems]="omitMenuItems" [customMenuItems]="customMenuItems"
                         [showActions]="showActions"></app-table-toolbar>
      <div [spinnerizer]="loading">
        <p-table [value]="data"
                 [(first)]="firstRow"
                 responsiveLayout="scroll"
                 dataKey="id"
                 [lazy]="true"
                 (onLazyLoad)="load($any($event))"
                 [lazyLoadOnInit]="false"
                 [rows]="pageSize"
                 [totalRecords]="total"
                 sortMode="multiple"
                 [multiSortMeta]="sortMeta"
                 [(selection)]="_selection"
                 (selectionChange)="onSelectionChange($event)"
                 [selectionPageOnly]="true"
                 [filters]="filters"

                 [paginator]="pageable"
                 [currentPageReportTemplate]="'shared.table.pageReport' | translate"
                 [rowsPerPageOptions]="[10, 25, 50, 100]"
                 [showCurrentPageReport]="true"

                 [scrollable]="true"
                 [scrollHeight]="pageable ? 'auto' : 'calc(100vh - 250px)'"

                 (onPage)="onPage($event)"
                 (onSort)="onSort($event)"
                 (onFilter)="onFilter($event)"
        >
          <ng-template #header pTemplate="header">
            <tr>
              @if (useCheckboxSelector) {
                <app-th-selector/>
              }
              <ng-container *ngTemplateOutlet="tplHeader; injector: primeTableInjector"></ng-container>
            </tr>
          </ng-template>
          <ng-template #body pTemplate="body" let-rowData>
            <tr>
              @if (useCheckboxSelector) {
                <app-td-selector [rowData]="rowData"/>
              }
              <ng-container
                *ngTemplateOutlet="tplBody; context: {$implicit: rowData}; injector: primeTableInjector"></ng-container>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  `,
  providers: [
    ColumnsInfoService,
    inheritanceProvider(AbstractEntityTable, EntityTableComponent),
  ],
  standalone: true,
  imports: [
    TableToolbarComponent, TableModule, SharedModule, ColumnHeaderSelectorComponent,
    NgTemplateOutlet, TableCellSelectorComponent, SpinnerizerDirective, TranslateModule
  ]
})
export class EntityTableComponent extends AbstractEntityTable<any> implements AfterContentInit {
  @Input() omitMenuItems: Array<string> = [];
  @Input() customMenuItems?: Array<ITableMenuItem<any>>;
  @Input() showActions = true;

  @Input() set pageable(val: boolean) {
    if (!val) {
      this.useFetchAll();
    }
    this._pageable = val;
  }

  get pageable() {
    return this._pageable;
  }

  @Input() useCheckboxSelector = true;

  private _pageable = true;
  protected tplHeader!: TemplateRef<any>;
  protected tplBody!: TemplateRef<any>;
  protected primeTableInjector!: Injector;
  @ContentChildren(PrimeTemplate) protected templates!: QueryList<PrimeTemplate>;

  @ViewChild(Table, {static: true}) set _primeTable(primeTable: Table) {
    this.primeTable = primeTable
  }

  @ViewChild(Table, {read: ViewContainerRef, static: true}) primeTableRef!: ViewContainerRef;
  @ViewChild(TableToolbarComponent, {static: true}) toolbar!: TableToolbarComponent;

  ngAfterContentInit(): void {
    this.templates.forEach((tpl) => {
      if (tpl.getType() === '$header') {
        this.tplHeader = tpl.template;
      } else if (tpl.getType() === '$body') {
        this.tplBody = tpl.template;
      }
    });
    this.createInjector();
  }

  private createInjector(): void {
    this.primeTableInjector = Injector.create({
      providers: [
        {provide: Table, useValue: this.primeTable}
      ],
      parent: this.primeTableRef.injector
    });
  }
}
