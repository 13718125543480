import {Component, Host, Input, OnInit, Optional, SkipSelf} from '@angular/core';
import {AbstractControl, ControlContainer} from '@angular/forms';
import {KeyValuePipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault} from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-control-error',
  template: `
    <div *ngIf="control && control.invalid && (control.dirty || control.touched)"
         class="mt-error-message" [ngClass]="styleClass || ''">
      <div *ngFor="let error of control.errors! | keyvalue" [class.mt-required]="error.key === 'required'">
        <ng-container *ngIf="error.value">
          <ng-container [ngSwitch]="error.key">
            <ng-container *ngSwitchCase="'required'">{{'shared.validationErrors.required' | translate}}</ng-container>
            <ng-container *ngSwitchCase="'minlength'">{{'shared.validationErrors.minlength' | translate: {count: control.errors?.['minlength']?.requiredLength} }}</ng-container>
            <ng-container *ngSwitchCase="'maxlength'">{{'shared.validationErrors.maxlength' | translate: {count: control.errors?.['maxlength']?.requiredLength} }}</ng-container>
            <ng-container *ngSwitchCase="'min'">{{'shared.validationErrors.min' | translate: {value: control.errors?.['min']?.min} }}</ng-container>
            <ng-container *ngSwitchCase="'max'">{{'shared.validationErrors.max' | translate: {value: control.errors?.['max']?.max} }}</ng-container>
            <ng-container *ngSwitchCase="'email'">{{'shared.validationErrors.email' | translate}}</ng-container>
            <ng-container *ngSwitchCase="'invalidUrl'">{{'shared.validationErrors.invalidUrl' | translate}}</ng-container>
            <ng-container *ngSwitchCase="'invalidData'">{{'shared.validationErrors.invalidData' | translate}}</ng-container>
            <ng-container *ngSwitchCase="'exists'">{{'shared.validationErrors.exists' | translate}}</ng-container>
            <ng-container *ngSwitchCase="'pattern'">{{error.value}}</ng-container>
            <ng-container *ngSwitchDefault>{{ error.key }}</ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  `,
  standalone: true,
  imports: [NgIf, NgClass, NgFor, NgSwitch, NgSwitchCase, NgSwitchDefault, KeyValuePipe, TranslateModule]
})
export class ControlErrorComponent implements OnInit {
  @Input() name?: string;
  @Input() styleClass?: string;
  @Input() control?: AbstractControl;
  @Input() dirtyOnInit = false;

  constructor(@Optional() @Host() @SkipSelf() private controlContainer: ControlContainer) {
  }

  ngOnInit() {
    if (!this.control && this.controlContainer && this.name) {
      this.control = this.controlContainer!.control!.get(this.name)!;
    }
    if (this.control && this.dirtyOnInit) {
      this.control.markAsDirty();
    }
  }
}

